import React from 'react';

const SubFooter = props => (
  <div className="sub-footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="sub-footer">
            
            <ul>
              <li className="copyright">
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                Lilardia Capital
              </li>
              <li>
                Digital Presence by <a href="https://www.itelasoft.com">itelasoft.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);


export default SubFooter;