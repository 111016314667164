import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import logo from '../images/logo-light.svg';

const Footer = props => (
  <div className="footer-strip">
    <div className="container">
     
      {/* <div className="footer">
        <div className="footer-logo">
          <div className="footer-title">
            <img alt="Lilardia Capital" className="img-fluid" src={logo} />
          </div>
        </div>
        <div className="footer-sec">
          <h5>Quick Links</h5>
          <ul className="footer-menu">
            <li>
              <Link to="/">Platform</Link>
            </li>
            <li>
              <Link to="/contact">Services</Link>
            </li>
            <li>
              <Link to="/contact">News</Link>
            </li>
            <li>
              <Link to="/contact">Resources</Link>
            </li>
          </ul>
        </div>
        <div className="footer-sec">
           <h5>About</h5>
          <ul className="footer-menu">
            <li>
              <Link to="/">About Lilardia Capital</Link>
            </li>
            <li>
              <Link to="/contact">Careers</Link>
            </li>
            <li>
              <Link to="/contact">Contact us</Link>
            </li>
            <li>
              <Link to="/contact">FAQ</Link>
            </li>
          </ul>
        </div>
        <div className="footer-sec">
          <h5>Legal</h5>
          <ul className="footer-menu">
            <li>
              <Link to="/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/contact">Legal & Regulatory</Link>
            </li>
            <li>
              <Link to="/contact">Terms of Service</Link>
            </li>
          </ul>
        </div>
        <div className="footer-sec">
          <div className="quick-contact">
            <ul className="contact-info">
              <li>
                <p>6 Timms Drive,<br/> Green Cape <br/>NSW <br/>2551</p>
              </li>
              <li>
                <a>(02) 5550 4839</a> 
              </li>
              <li>
                <a>info@lilardiacapital.com</a>
              </li>
            </ul>
          </div>
          <ul className="social">
              <li>
                <a href="https://www.linkedin.com/company/lilardia-capital/">
                  <i class="fab fa-linkedin-in" />
                </a>
              </li>
            
              <li>
                <a href="/">
                  <i class="fab fa-facebook" />
                </a>
              </li>
              <li>
                <a href="/">
                  <i class="fab fa-twitter" />
                </a>
              </li>
             
            </ul>
        </div>
      </div> */}
    </div>
      <div className="container">
        <div className="footer">          
        <div className="footer-logo">
            <div className="footer-title">
              <img alt="Lilardia Capital" className="img-fluid" src={logo} />
            </div>
          </div>
          <div className="footer-sec">            
            <ul className="footer-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">What We Offer</Link>
              </li>
              <li>
                <Link to="/aboutUs">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact us</Link>
              </li>
            </ul>
          </div>
          <div className="footer-sec">
            <div className="quick-contact">
              <ul className="contact-info">
                <li>
                  <p>38 North Canal Road,<br></br> Singapore 059294</p>
                </li>
                <li className="mt-1">
                  <a href="mailto:eratnayake@lilardiacapital.com?Subject=Lilardia%20Capital%20More%20Info">eratnayake@lilardiacapital.com</a>
                </li>
              </ul>
            </div>
          </div>
          <ul className="social">
          <p>Follow us on</p>
            <li>
              <a href="https://www.linkedin.com/company/lilardia-capital/">
                <i class="fab fa-linkedin-in" />
              </a>
            </li>           
          </ul>
        </div>
      </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
